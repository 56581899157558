<template>
  <div class="permissionDetail">
    <template>
      <div class="baseInfo info">
        <p class="pub_head">基本信息</p>
        <div class="pub_cont">
          <el-descriptions title="">
            <el-descriptions-item label="学校">{{
              orderDetailsData.schoolName
            }}</el-descriptions-item>
            <el-descriptions-item label="教学点">{{
              orderDetailsData.stationName
            }}</el-descriptions-item>
            <el-descriptions-item label="批次">{{
              orderDetailsData.admissionBatch
            }}</el-descriptions-item>
            <el-descriptions-item label="提交时间">{{
              $way.timestampReturnDate(orderDetailsData.createTime, "datetime")
            }}</el-descriptions-item>
            <el-descriptions-item label="审批单人数">{{
              orderDetailsData.studentNum
            }}</el-descriptions-item>
            <!-- <el-descriptions-item label="提交人">{{
              orderDetailsData.createBy
            }}</el-descriptions-item> -->
          </el-descriptions>
        </div>
      </div>
      <div class="payInfo info">
        <p class="pub_head">审批单信息</p>
        <div class="pub_cont">
          <!-- <el-descriptions title="支付信息" class="sub_title">
            <el-descriptions-item label="学生数量">{{
              orderDetailsData.studentNum
            }}</el-descriptions-item> -->
            <!-- <el-descriptions-item label="应收金额">{{
              orderDetailsData.amountReceivable
            }}</el-descriptions-item>
            <el-descriptions-item label="实收金额">{{
              orderDetailsData.amountActual
            }}</el-descriptions-item>
            <el-descriptions-item label="待收金额">{{
              orderDetailsData.uncollectedAmount
            }}</el-descriptions-item>
            <el-descriptions-item label="收费单价">{{
              orderDetailsData.unitPrice
            }}</el-descriptions-item> -->
          <!-- </el-descriptions> -->
          <!-- <el-descriptions title="退款信息" class="sub_title">
            <el-descriptions-item label="是否退款">{{
              orderDetailsData.studentNum || '/'
            }}</el-descriptions-item>
            <el-descriptions-item label="退款总金额">{{
              orderDetailsData.amountReceivable || '/'
            }}</el-descriptions-item>
            <el-descriptions-item label="个人缴费退款金额">{{
              orderDetailsData.amountActual || '/'
            }}</el-descriptions-item>
            <el-descriptions-item label="学校统缴退款金额">{{
              orderDetailsData.uncollectedAmount || '/'
            }}</el-descriptions-item>
            <el-descriptions-item label="收款账号">{{
              orderDetailsData.unitPrice || '/'
            }}</el-descriptions-item>
            <el-descriptions-item label="收款银行">{{
              orderDetailsData.unitPrice || '/'
            }}</el-descriptions-item>
          </el-descriptions> -->
          <div class="school_smallArea">
            <p class="small_title">学生名单</p>
            <div>
              <closeStudentListUrl :orderDetailsData="orderDetailsData"></closeStudentListUrl>
            </div>
          </div>
          <div class="audit_smallArea">
            <p class="small_title">审批凭证</p>
            <div class="pic_showArea" v-if="!isShowEdit">
              <img
                v-for="(item, index) in orderDetailsData.voucherUrl"
                :key="index"
                class="audit_pic"
                :src="item"
                alt=""
                @click="watchEvent(orderDetailsData.voucherUrl, index)"
              />
            </div>
            <el-upload
              v-else
              action="#"
              class="elUploads"
              list-type="picture-card"
              ref="uploads"
              accept="image/png, image/jpeg, image/jpg"
              :auto-upload="false"
              :limit="5"
              :file-list="fileData"
              :on-exceed="exceedUpload"
              :on-change="changeEvent"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove">
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
          <!-- <div class="school_smallArea">
            <p class="small_title">学生名单</p>
            <div class="studentlist">
              <el-button
                @click="toStudentList($route.query)"
                type="primary"
                plain
                >查看学生名单</el-button
              >
              <div class="downLoadFile">
                <span style="color: red;">{{errorTips}}</span>
              </div>
            </div>
          </div> -->
          <div class="remark_smallArea">
            <p class="small_title">备注</p>
            <el-input
              v-if="!isShowEdit"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
              class="text_area"
              placeholder="请输入"
              v-model="orderDetailsData.remark"
              disabled
            >
            </el-input>
            <el-input
              v-else
              class="text_re"
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="editForm.remark">
            </el-input>
          </div>
        </div>
      </div>
      <div class="payInfo info">
        <p class="pub_head">审批操作</p>
        <div class="pub_cont">
          <div class="audit_opinion" v-if="$route.query.status == 0 && $route.query.type == 'audit'">
            <p class="small_title">审批意见</p>
            <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
              class="text_area"
              placeholder="请输入"
              v-model="form.approvalComments"
            >
            </el-input>
            <div class="btn_audit_area">
              <el-button
                type="warning"
                plain
                @click="auditDatilsEvent('refuse')"
                >审核不通过</el-button
              >
              <el-button type="primary" @click="auditDatilsEvent('agree')"
                >审核通过</el-button
              >
            </div>
          </div>
          <div class="audit_opinion" v-else>
            <el-descriptions>
              <el-descriptions-item label="审批人">{{
                orderDetailsData.verifyBy || '暂无'
              }}</el-descriptions-item>
              <el-descriptions-item label="审批时间">{{
                $way.timestampReturnDate(orderDetailsData.approveTime, "datetime")|| '暂无'
              }}</el-descriptions-item>
              <el-descriptions-item label="审批状态">{{
                String(orderDetailsData.status) == "0"
                  ? "待审核"
                  : String(orderDetailsData.status) == 1
                  ? "审核通过"
                  : "审核不通过"
              }}</el-descriptions-item>
              <el-descriptions-item label="审批意见">{{
                orderDetailsData.approvalComments || "暂无"
              }}</el-descriptions-item>
            </el-descriptions>
          </div>
        </div>
      </div>
      <div class="notPass-btn">
        <template v-if="$route.query.type == 'edit'">
          <!-- <el-button type="primary" @click="editBase">编辑基本信息</el-button> -->
          <el-button type="primary" @click="editEvent">保存</el-button>
          <el-button type="primary" @click="resubmit">重新提交</el-button>
        </template>
        <el-button @click="goBack">返回列表</el-button>
      </div>
      <el-image-viewer
        v-if="showViewer"
        :initial-index="showIndex"
        :on-close="watchEvent"
        :url-list="voucherUrlsPic"
      />
    </template>
    <dialogDiy
      :isShow="isCloseShow"
      :dialogDiyData="dialogDiyData"
      @dialogEvent="dialogDiyEvent"
    >
      <div slot="dialogContent" class="dia_opera">
        <img class="errIcon" src="@/assets/img/warn.png" alt="" />
        <p>{{diaType == 1 ? '确定要关闭该审批单吗？' : '确定要重新提交该审批单吗？'}}</p>
        <div class="btn_area">
          <el-button @click="batchDelete">取 消</el-button>
          <el-button class="mainBtn" @click="doEvent">确 定</el-button>
        </div>
      </div>
    </dialogDiy>
    <!-- 补缴 -->
    <el-dialog
      class="compile"
      title="补缴操作"
      :visible.sync="isShowPayment"
      @close="cancelPay"
    >
      <el-form :model="forms" label-position="right" label-width="100px" :rules="rules">
        <el-form-item label="补缴金额:" prop="makeUpMoney">
          <el-input
            @keyup.enter.native="paymentEvents"
            v-model="forms.makeUpMoney"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="上传付款凭证:">
          <el-upload
            action="#"
            class="elUploads"
            list-type="picture-card"
            ref="upload"
            accept="image/png, image/jpeg, image/jpg"
            :auto-upload="false"
            :limit="5"
            :on-exceed="exceedUpload"
            :on-change="changeEvent"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <!-- <div class="uploads" @click="getLocalPic" v-if="forms.voucherUrl.length == 0">
            <img src="../../assets/img/adds.png" alt="">
          </div>
          <template v-else>
            <img class="up_img" :src="forms.voucherUrl" alt="">
          </template> -->
        </el-form-item>
        <el-form-item label="备注:">
          <el-input
            type="textarea"
            placeholder="备注"
            v-model="forms.remark"
            maxlength="500"
            show-word-limit
            @keyup.enter.native="paymentEvents"
            :autosize="{ minRows: 2, maxRows: 6 }"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelPay">取 消</el-button>
        <el-button type="primary" @click="paymentEvents">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 修改基本信息 -->
    <el-dialog
      class="edit-base"
      title="编辑"
      :visible.sync="isEditBase"
    >
      <div class="edit-model">
        <p class="area_head">上传审批凭证</p>

        <el-upload
          action="#"
          class="elUploads"
          list-type="picture-card"
          ref="uploads"
          accept="image/png, image/jpeg, image/jpg"
          :auto-upload="false"
          :limit="5"
          :file-list="fileData"
          :on-exceed="exceedUpload"
          :on-change="changeEvent"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove">
          <i class="el-icon-plus"></i>
        </el-upload>
      </div>
      <div class="edit-model">
        <p class="area_head">备注</p>
        <el-input
          class="text_re"
          type="textarea"
          :rows="2"
          placeholder="请输入内容"
          v-model="editForm.remark">
        </el-input>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="editEvent">确 定</el-button>
        <el-button @click="editBase">取 消</el-button>
      </div>
    </el-dialog>
    <input
      style="display: none"
      type="file"
      @change="picChange()"
      ref="loadPic"
      accept="image/*"
    />
  </div>
</template>

<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import dialogDiy from "../../commonModule/dialogDiy";
import closeStudentListUrl from './closeStudentListUrl'
const OSS = require("ali-oss");
export default {
  name: "permissionDetail",
  components: {
    ElImageViewer,
    dialogDiy,
    closeStudentListUrl
  },
  data() {
    return {
      id: "",
      types: "",
      orderDetailsData: {},
      form: {
        approvalComments: null,
        id: null,
        status: null,
        taskFlag: null
      },
      isShowPayment: false,
      forms: {
        verifyId: "",
        makeUpMoney: "",
        remark: "",
        voucherUrl: [],
      },
      voucherUrlsPic: [],
      showViewer: false,
      isShowAuditPic: false,
      isCloseShow: false,
      dialogDiyData: {
        title: "",
        sizeStyle: { width: "400px", height: "234px" },
      },
      fileData: [],
      showIndex: 0,
      isEditBase: false,
      editForm: {},
      diaType: 1,
      editForm: {
        id: null,
        remark: null,
        voucherUrl: []
      },
      errorTips: '',
      rules: {
        makeUpMoney: [
          { required: true, message: "请输入补缴金额", trigger: "blur" }
        ]
      },
      isShowEdit: false,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        this.types = newVal.query.type;
        this.id = newVal.query.id;
        this.getAudioDetail();
      },
    },
  },
  methods: {
    // timestampReturnDate: this.$way.timestampReturnDate,
    // 获取关闭权限审批页面数据
    getAudioDetail() {
      this.$request.fincloseverifyDetails({ id: this.id }).then(res => {
        if (res.data.code == 0) {
          // console.log(res.data);
          this.orderDetailsData = res.data.data;
          console.log(this.orderDetailsData,9852411)
          this.orderDetailsData.voucherUrl = this.orderDetailsData.voucherUrl ? JSON.parse(this.orderDetailsData.voucherUrl) : []
          this.getAuditErrTipsData()
          if(this.$route.query.type == 'edit') {
            this.isShowEdit = true
            this.getBaseData()
          }
        }
      });
    },
    getBaseData() {
      this.editForm.amountActual = this.orderDetailsData.amountActual;
      this.editForm.arrivalTime = this.orderDetailsData.arrivalTime;
      // this.editForm.feeType = this.orderDetailsData.feeType;
      this.editForm.id = this.id;
      this.editForm.payAccount = this.orderDetailsData.payAccount;
      this.editForm.payAccountName = this.orderDetailsData.payAccountName;
      this.editForm.payBank = this.orderDetailsData.payBank;
      this.editForm.payTime = this.orderDetailsData.payTime;
      this.editForm.remark = this.orderDetailsData.remark;
      this.editForm.voucherUrl = this.orderDetailsData.voucherUrl;
      this.fileData = [];
      if(this.editForm.voucherUrl.length != 0) {
        this.editForm.voucherUrl.forEach((item, index) => {
          this.fileData.push({
            name: 'voucher',
            url: item
          })
        })
      }
    },
    editEvent() {
      let arr = this.fileData.map(item=>item.raw);
      let vouArr = this.fileData.filter(item=>item.name == 'voucher').map(item => item.url);
      let filterArr = arr.filter(Boolean);
      if(filterArr.length != 0) {
        // 多图片上传
        this.$request.uploadPics(filterArr).then( res=>{
          if(res.data.code == 0) {
            console.log(res.data);
            vouArr.push.apply(vouArr, res.data.data);
            this.editForm.voucherUrl = vouArr;
            this.editAuditEvent();
          }
        })
      } else {
        this.editForm.voucherUrl = vouArr
        this.editAuditEvent();
      }
    },
    editAuditEvent() {
      this.$request.editFincloseverify(this.editForm).then(res => {
        if(res.data.code == 0) {
          this.Success(res.data.msg);
          this.getAudioDetail();
          // this.editBase();
        }
      })
    },
    // 下载文档
    downLoadFile(url) {
      if (!url) {
        this.Warn("当前无文件可下载");
        return;
      }
      window.location.href = url;
    },

    // 查看学生名单
    toStudentList(row) {
      this.$router.push({
        name: "closeStudentListUrl",
        query: {
          id: row.id,
          status: row.status,
          type: this.$route.query.type
        },
      });
      // this.$router.go(0)
    },
    // 获取学生名单错误信息
    getAuditErrTipsData() {
      this.$request.closeAuditErrTips({id: this.id}).then( res => {
        if(res.data.code == 0) {
          this.errorTips = res.data.data > 0 ? '该名单存在错误数据，请点击查验' : '';
        }
      })
    },
    // 审核通过或审核不通过
    auditDatilsEvent(type) {
      this.form.id = this.id;
      this.form.taskFlag = this.orderDetailsData.taskFlag;
      this.form.status = type == "refuse" ? 2 : 1;
      if (this.form.taskFlag != 1) {
        this.Warn("审批单正在处理中无法审批,稍后重试......");
        return;
      }
      this.$request.enterFincloseverify(this.form).then((res) => {
        if (res.data.code == 0) {
          this.Success(res.data.msg);
          this.goBack();
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    // 补缴
    paymentEvent(row) {
      this.isShowPayment = !this.isShowPayment;
    },
    paymentEvents() {
      if (!this.forms.makeUpMoney) {
        this.Warn("补缴金额不为空！");
        return;
      }
      if (
        this.forms.makeUpMoney > this.orderDetailsData.uncollectedAmount ||
        Number(this.forms.makeUpMoney) < 0
      ) {
        this.Warn("补缴金额输入有误,不能输入负数或者大于待收金额");
        return;
      }
      if (this.fileData.length != 0) {
        let arr = this.fileData.map((item) => item.raw);
        // 多图片上传
        this.$request.uploadPics(arr).then((res) => {
          if (res.data.code == 0) {
            console.log(res.data)
            this.forms.voucherUrl = res.data.data;
            this.enterPay();
          }
        });
      } else {
        this.enterPay();
      }
    },
    // 确定补缴
    enterPay() {
      console.log(this.forms);
      this.forms.verifyId = this.id;
      this.$request.makeUpEvent(this.forms).then((res) => {
        if (res.data.code == 0) {
          this.Success(res.data.msg);
          this.isShowPayment = false;
          this.getAudioDetail();
        }
      });
    },
    cancelPay() {
      this.isShowPayment = false;
      this.forms.makeUpMoney = "";
      this.forms.remark = "";
      this.forms.voucherUrl = [];
    },
    // 确认上传
    getLocalPic() {
      this.$refs.loadPic.value = "";
      this.$refs.loadPic.click();
    },
    async picChange() {
      let inputDOM = this.$refs.loadPic;
      let files = inputDOM.files[0];
      this.fileName = files.name;
      let ossUrl = await this.$way.uploadOss(OSS, files, "img/permissionDetail");
      console.log(ossUrl);
      this.forms.voucherUrl.push(ossUrl);
    },
    // 删除
    deleteEvent(row) {
      this.$request.deleteByAuditPayId({ id: row.id }, (res) => {
        if (res.code == 0) {
          this.Success(res.msg);
          this.getAudioDetail();
        }
      });
    },
    // 查看凭证
    watchEvent(urlArr, showIndex = 0) {
      this.showViewer = !this.showViewer;
      if (this.showViewer) {
        // console.log(showIndex, 777);
        this.voucherUrlsPic = urlArr;
        this.showIndex = showIndex;
      }
    },
    closeUpPayEvent() {
      this.diaType = 1;
      this.isCloseShow = !this.isCloseShow;
    },
    doEvent() {
      this.$request.againFincloseverify({id: this.id}).then(res => {
        if(res.data.code == 0) {
          this.Success(res.data.msg)
          this.goBack()
        }
      })
      // if(this.diaType == 1) {
      //   this.$request.closeUpPay({ id: this.id }).then((res) => {
      //     if (res.data.code == 0) {
      //       this.Success(res.data.data);
      //       this.goBack();
      //     }
      //   });Eway
      // } else {
      //   this.$request.auditReSubmit({id: this.id}).then(res => {
      //     if(res.data.code == 0) {
      //       this.Success(res.data.msg);
      //       this.goBack();
      //     }
      //   })
      // }
    },
    dialogDiyEvent(val) {
      this.isCloseShow = val.isShow;
    },
    batchDelete() {
      this.isCloseShow = !this.isCloseShow;
    },
    exceedUpload() {
      this.Warn("最大图片上传数量为5");
    },
    async changeEvent(file, fileList) {
      // console.log(file, fileList,2223222222);
      // if (file.raw.size > 1024 * 100) {
      //   this.$message.error("海报大于100KB,不能上传");
      //   return;
      // }
      this.fileData = fileList;
    },
    handleRemove(file, fileList) {
      this.fileData = fileList;
    },
    handlePictureCardPreview(file) {
      this.voucherUrlsPic = [file.url];
      this.showViewer = true;
    },
    editBase() {
      this.isEditBase = !this.isEditBase;
    },
    resubmit() {
      this.diaType = 2;
      this.isCloseShow = !this.isCloseShow;
    },
  },
};
</script>

<style lang="less">
.permissionDetail {
  height: calc(~"100% - 68px");
  background-color: #fff;
  box-sizing: border-box;
  padding: 20px 40px;
  .info {
    margin-top: 20px;
    border: 1px solid #f2f2f2;
  }
  .pub_head {
    height: 60px;
    line-height: 60px;
    box-sizing: border-box;
    padding-left: 29px;
    background: #f2f2f2;
    font-size: 18px;
    font-weight: 600;
  }
  .pub_cont {
    box-sizing: border-box;
    padding: 20px 40px;
    .sub_title {
      .el-descriptions__title {
        color: #1282ff;
        border-left: 3px solid #1282ff;
        padding-left: 11px;
        font-weight: 500;
      }
    }
    .small_title {
      color: #1282ff;
      border-left: 3px solid #1282ff;
      padding-left: 11px;
      margin-bottom: 20px;
    }
    .studentlist {
      height: 40px;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .downLoadFile {
        display: flex;
        line-height: 40px;
        margin-left: 40px;
        font-size: 12px;
        a{
        font-size: 12px;
        color: #000;
        }
      }
    }
  }
  .baseInfo {
    .info_area {
      display: flex;
      padding-top: 40px;
      background: #ffffff;
      border: 1px solid #f2f2f2;
      & > ul {
        margin-left: 40px;
        img {
          width: 100px;
          height: 140px;
        }
      }
    }
  }
  .payInfo {
    .pic_showArea {
      display: flex;
      .audit_pic {
        width: 100px;
        height: 100px;
        background-color: #e1e1e1;
        margin-bottom: 20px;
        margin-right: 20px;
        cursor: pointer;
      }
    }
    .text_area {
      width: 400px;
    }
    .btn_audit_area {
      margin-top: 20px;
    }
  }
  .payFees {
    ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background-color: #fff;
    }
    ::-webkit-scrollbar-thumb {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    .el-table__header .has-gutter th.is-leaf:nth-last-child(2) {
      border-right: none;
    }
    .btn_pay_area {
      text-align: right;
      margin-top: 20px;
    }
  }
  .notPass-btn {
    margin-top: 20px;
    text-align: center;
  }
  .edit-base {
    .edit-model {
      border-bottom: 1px solid #E1E1E1;
      padding-bottom: 20px;
      .area_head {
        padding: 10px 0;
      }
    }
  }
}
.el-image-viewer__wrapper {
  z-index: 9999!important;
}
</style>