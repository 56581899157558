import { render, staticRenderFns } from "./permissionDetail.vue?vue&type=template&id=6f2e24fc"
import script from "./permissionDetail.vue?vue&type=script&lang=js"
export * from "./permissionDetail.vue?vue&type=script&lang=js"
import style0 from "./permissionDetail.vue?vue&type=style&index=0&id=6f2e24fc&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports